import { Component } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
// import map from '../assets/map/Map.json';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'india-map';
 
}
